import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Input, Dropdown } from '@flogistix/flo-ui';

import { InspectionType } from '../../../../classes/enums';
import { InspectionContext } from '../../../../context/InspectionContext';
import { GlobalContext } from '../../../../context/GlobalContext';
import { generateFlogistixId, formatDate } from '../../shared/utils';
import FileLabel from '../../../../components/Shared/FileLabel/FileLabel';
import FormLabel from '../../../../components/Shared/FormLabel/FormLabel';
import Datepicker from '../../../../components/Datepicker/Datepicker';
import { DropdownOptionType } from '../../shared/typings';
import StatusDropzone from '../../../../components/FileDropzone/StatusDropzone/StatusDropzone';
import AirMethaneFile, { UploadFile } from '../../../../classes/airmethane-file';
import { fetchFilesForInspection } from '../../../../services/airmethaneApi';
import Survey from '../../../../classes/survey';

const PartOne = () => {
  const { user } = useAuth0();
  const {
    inspection, token, setCompletedSectionsOnChange, hasAllRequiredFields
  } = useContext(InspectionContext);
  const { orgs } = useContext(GlobalContext);

  const customerOptions: DropdownOptionType[] = orgs.map((org: { orgName: string, netsuiteId: string }) => ({
    value: `${org.netsuiteId}`,
    label: `${org.orgName}`
  }));

  const inspectionTypeLabels = {
    [InspectionType.FACILITY]: 'Facility',
    [InspectionType.PIPELINE]: 'Pipeline',
    [InspectionType.VISUAL]: 'Visual'
  };

  const inspectionTypeOptions = Object.values(InspectionType).map((type) => ({
    value: type,
    label: inspectionTypeLabels[type]
  }));

  const [inspectionType, setInspectionType] = useState(
    inspection?.inspectionType || null
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCustomer, setSearchCustomer] = useState('');
  const [searchSite, setSearchSite] = useState('');
  const [inspectionDate, setInspectionDate] = useState(
    inspection?.inspectionDate ? formatDate(inspection.inspectionDate) : ''
  );
  const [customer, setCustomer] = useState<DropdownOptionType | null>(
    inspection?.org
      ? { value: `${inspection.org.id}`, label: inspection.org.name }
      : null
  );
  const [siteOptions, setSiteOptions] = useState<DropdownOptionType[]>([]);
  const [site, setSite] = useState<DropdownOptionType | null>(
    inspection?.site
      ? { value: `${inspection.site.id}`, label: inspection.site.name }
      : null
  );
  const [inspectionState, setInspectionState] = useState(inspection?.state || '');
  const [inspectionCounty, setInspectionCounty] = useState(inspection?.county || '');
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [inspectionFiles, setInspectionFiles] = useState<AirMethaneFile[]>(inspection?.files || []);

  const handleInspectionTypeChange = (selectedOption: DropdownOptionType) => {
    if (selectedOption) {
      const value = selectedOption.value as InspectionType;
      inspection!.updateInspectionType(token, value).then(() => {
        setCompletedSectionsOnChange();
        setInspectionType(value);
      });
    }
  };

  const handleInspectionDateChange = (dateString: string) => {
    const inputDate = new Date(dateString);
    if (Number.isNaN(inputDate.getTime())) {
      return inspection!.removeInspectionDate(token).then(() => {
        setInspectionDate('');
      });
    }
    return inspection!.updateInspectionDate(token, inputDate.toISOString()).then(() => {
      setCompletedSectionsOnChange();
      setInspectionDate(inputDate.toISOString());
    });
  };

  const handleCustomerChange = async (selectedOption: DropdownOptionType) => {
    if (selectedOption.value === customer?.value) return;
    if (selectedOption) {
      const { value } = selectedOption;
      const selectedCustomer = orgs.find(
        (org: { orgName: string; netsuiteId: string }) => `${org.netsuiteId}` === value
      );
      if (selectedCustomer) {
        const customerPayload = {
          id: selectedCustomer.netsuiteId,
          name: selectedCustomer.orgName
        };
        const emptySitePayload = { id: '', name: '' };

        if (site?.value) await setSite({ value: '', label: '' });
        await setCustomer(selectedOption);
        if (site?.value) await inspection?.updateSite(token, emptySitePayload);
        await inspection?.updateOrg(token, customerPayload);
        setCompletedSectionsOnChange();
      }
    }
  };

  const handleSiteChange = async (selectedOption: DropdownOptionType) => {
    if (selectedOption) {
      const { value } = selectedOption;
      const selectedSite = siteOptions.find((option) => option.value === value);

      if (selectedSite) {
        const sitePayload = {
          id: selectedSite.value as string,
          name: selectedSite.label
        };
        await setSite(selectedOption);
        await inspection?.updateSite(token, sitePayload);
        setCompletedSectionsOnChange();
      }
    }
  };

  const handleInspectionStateChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const state = event.target.value as string;
    if (!state.length) {
      inspection!.removeState(token);
    } else if (!(state === inspection!.state)) {
      inspection!.updateInspectionState(token, state);
    }
    setCompletedSectionsOnChange();
    setInspectionState(state);
  };

  const handleInspectionCountyChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const county = event.target.value as string;
    if (!county.length) {
      inspection!.removeCounty(token);
    } else if (!(county === inspection!.county)) {
      inspection!.updateInspectionCounty(token, county);
    }
    setCompletedSectionsOnChange();
    setInspectionCounty(county);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    files: UploadFile[]
  ) => {
    try {
      if (!inspection?.flogistixId) { throw new Error('No FlogistixId on inspection.'); }
      await inspection?.addFiles(token, files);
      setUploadFiles((prev) => [...prev, ...files]);
    } catch (error) {
      console.error('Error adding files:', error);
    }
  };

  const handleRemoveFile = async (fileId?: string) => {
    if (!fileId) return;

    const fileInUploadFiles = uploadFiles.find((file) => file.id === fileId);
    const fileInInspectionFiles = inspectionFiles.find((file) => file.id === fileId);
    const fileToRemove = fileInUploadFiles || fileInInspectionFiles;
    const flogistixId = inspection?.flogistixId;

    if (!flogistixId) return;

    try {
      await Promise.all(
        (inspection?.verifications ?? [])
          .map((verification) => {
            if (verification.file?.id === fileId) {
              return verification.detachFile(token, flogistixId, inspection.id as string);
            }
            return null;
          })
          .filter(Boolean)
      );

      if (inspection?.locationSignFile?.id === fileId) {
        await inspection.detachLocationSignFile(token);
      }

      await Promise.all(
        (inspection?.leaks ?? []).map(async (leak) => {
          const leakTasks = leak.files?.some((f) => f.id === fileId)
            ? [leak.detachFile(token, flogistixId, inspection.id as string, fileId)]
            : [];

          const leakRepairTasks = (leak.leakRepairs ?? [])
            .map((leakRepair) => {
              if (leakRepair.file?.id === fileId) {
                return leakRepair.detachFile(token, flogistixId, inspection.id as string, inspection);
              }
              return null;
            })
            .filter(Boolean);

          return Promise.all([...leakTasks, ...leakRepairTasks]);
        })
      );

      await Promise.all(
        (inspection?.surveys ?? [])
          .map((survey) => {
            if (survey.files?.some((f) => f.id === fileId)) {
              const updatedFiles = survey.files.filter((f) => f.id !== fileId);
              return survey.patchSurvey(token, flogistixId, inspection, {
                ...survey,
                files: updatedFiles,
                pendingFiles: []
              } as unknown as Survey);
            }
            return null;
          })
          .filter(Boolean)
      );

      if (fileToRemove) {
        await inspection?.removeFile(token, fileToRemove.id as string);

        setUploadFiles((prev) => prev.filter((file) => file.id !== fileId));
        setInspectionFiles((prev) => prev.filter((file) => file.id !== fileId));
      }
    } catch (error) {
      console.error('Error removing file:', error);
    }
  };

  const fetchInspectionFiles = async () => {
    if (inspection?.id && token) {
      const files = await fetchFilesForInspection(inspection.id, token);
      setInspectionFiles(files);
    }
  };

  useEffect(() => {
    if (customer?.value) {
      const sitesOnOrg = orgs.find(
        (org: { orgName: string, netsuiteId: string }) => `${org.netsuiteId}` === customer?.value
      )?.sites;
      const formattedSiteOptions = sitesOnOrg?.map((siteOnOrg: { siteName: string, netsuiteId: string }) => ({
        value: `${siteOnOrg.netsuiteId}`,
        label: `${siteOnOrg.siteName}`
      }));
      setSiteOptions(formattedSiteOptions || []);
    }
    fetchInspectionFiles();
  }, []);

  useEffect(() => {
    const sitesOnOrg = orgs.find(
      (org: { orgName: string, netsuiteId: string }) => `${org.netsuiteId}` === customer?.value
    )?.sites;
    const formattedSiteOptions = sitesOnOrg?.map((siteOnOrg: { siteName: string, netsuiteId: string }) => ({
      value: `${siteOnOrg.netsuiteId}`,
      label: `${siteOnOrg.siteName}`
    }));
    setSiteOptions(formattedSiteOptions || []);
  }, [customer]);

  useEffect(() => {
    if (!inspection?.flogistixId && hasAllRequiredFields) {
      const newFlogistixId = generateFlogistixId(user?.name ?? 'UNKN');
      inspection!.updateFlogistixId(newFlogistixId).then(() => {
        inspection!.createInspection(token);
      });
    }
  }, [hasAllRequiredFields, inspection, token, user?.name]);

  useEffect(() => {
    const channel = new BroadcastChannel('upload-status-channel');

    channel.onmessage = async (event) => {
      if (event.data.type === 'UPLOAD_STATUS') {
        await fetchInspectionFiles();
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  if (!customerOptions.length) return <h1>Loading...</h1>;

  return (
    <>
      <h1 className="page-details__header">Site details</h1>
      <h4 className="part">Part 1 of 2</h4>
      <FormLabel>Inspection type</FormLabel>
      <Dropdown
        id="inspection-type"
        value={inspectionType ? inspectionTypeLabels[inspectionType] : ''}
        onSelect={handleInspectionTypeChange}
        className="input__select"
        placeholder="Select type"
        options={inspectionTypeOptions.filter(
          (option) => !searchTerm || option.label.toLowerCase().includes(searchTerm.toLowerCase())
        )}
        searchValue={searchTerm}
        onSearchChange={(value) => setSearchTerm(value)}
        fixedWidth="100%"
      />
      <FormLabel>Inspection date</FormLabel>
      <Datepicker id="inspection-date" selected={inspectionDate || ''} onChange={handleInspectionDateChange} />
      <FormLabel>Customer</FormLabel>
      <Dropdown
        id="customer"
        value={customer?.label ?? ''}
        onSelect={handleCustomerChange}
        className="input__select"
        options={customerOptions.filter(
          (option) => !searchCustomer || option.label.toLowerCase().includes(searchCustomer.toLowerCase())
        )}
        placeholder="Select a customer"
        searchValue={searchCustomer}
        onSearchChange={(value) => setSearchCustomer(value)}
        fixedWidth="100%"
      />
      <FormLabel>Site name</FormLabel>
      <Dropdown
        id="site-name"
        value={site?.label ?? ''}
        onSelect={handleSiteChange}
        className="input__select"
        options={siteOptions.filter((option) => option.label.toLowerCase().includes(searchSite.toLowerCase()))}
        placeholder="Dependent on customer"
        searchValue={searchSite}
        onSearchChange={(value) => setSearchSite(value)}
        fixedWidth="100%"
      />
      <FormLabel>State</FormLabel>
      <Input
        id="state"
        type="text"
        name="state"
        defaultValue={inspectionState}
        onBlur={handleInspectionStateChange}
        placeholder="Dependent on site"
        className="input__text"
        fixedWidth="100%"
      />
      <FormLabel>County</FormLabel>
      <Input
        id="county"
        type="text"
        name="county"
        defaultValue={inspectionCounty}
        onBlur={handleInspectionCountyChange}
        placeholder="Dependent on site"
        className="input__text"
        fixedWidth="100%"
      />
      <FileLabel acceptedTypes=".jpg, .png, .heic, .mp4, .pdf, .csv or .mov">Upload files</FileLabel>
      <StatusDropzone
        onFileChange={handleFileChange}
        acceptedTypes=".png, .jpg, .jpeg, .heic, .mp4, .mov, .avi, .pdf, .csv, text/csv or .mov"
        removeHandler={handleRemoveFile}
        fileIds={
          [
            ...(inspectionFiles ?? [])
          ]?.map((file) => file.id)?.filter((id = '') => !!id) as string[] ?? []
        }
        disabled={!hasAllRequiredFields}
      />
      <span className="spacer" />
    </>
  );
};

export default PartOne;
